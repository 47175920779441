<template>
  <b-card no-body>
    <b-row>
      <b-col
        v-for="setting in settings"
        :key="setting.title"
        cols="12"
        sm="6"
        :class="{
          hidden: ['game.simpleplay'].includes(setting.key)
        }"
      >
        <SettingComponent
          :header-title="setting.title"
          :setting-key="setting.key"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import SettingComponent from '@/components/settings/accounts-settings/SettingComponent'

export default {
  components: {
    SettingComponent,
  },
  data() {
    return {
      settings: [
        { title: 'PG', key: 'game.pgslot' },
        { title: 'PGSOFT2', key: 'game.pgsoft2' },
        { title: 'JOKER', key: 'game.slot' },
        { title: 'SLOTGROUPQT', key: 'game.slotgroupqt' },
        { title: 'SLOTGROUPSAPI', key: 'game.slotgroupsapi' },
        { title: 'RICH88', key: 'game.rich88' },
        { title: 'AMBPOKER', key: 'game.ambpoker' },
        { title: 'SPADEGAMING', key: 'game.spadegaming' },
        { title: 'FUNKYGAMES', key: 'game.funkygames' },
        { title: 'PRAGMATICPLAY', key: 'game.pragmaticplay' },
        { title: 'KINGMAKER', key: 'game.kingmaker' },
        { title: 'AEGAMING', key: 'game.aegaming' },
        { title: 'CQ9', key: 'game.cq9' },
        { title: 'EVOPLAY', key: 'game.evoplay' },
        { title: 'MANNAPLAY', key: 'game.mannaplay' },
        { title: 'KAGAMING', key: 'game.kagaming' },
        { title: 'JILI', key: 'game.jili' },
        { title: 'BOOONGO', key: 'game.booongo' },
        { title: 'WAZDAN', key: 'game.wazdan' },
        { title: 'ICONICGAMING', key: 'game.iconicgaming' },
        { title: 'FUNTAGAMING', key: 'game.funtagaming' },
        { title: 'TOPGRADEGAMES', key: 'game.topgradegames' },
        { title: 'YGGDRASILDIRECT', key: 'game.yggdrasildirect' },
        { title: 'SLOTMILL', key: 'game.slotmill' },
        { title: 'JUSTPLAY', key: 'game.justplay' },
        { title: 'MEN5GAMING', key: 'game.men5gaming' },
        { title: 'ALLWAYSPIN', key: 'game.allwayspin' },
        { title: 'HABANEROGAMING', key: 'game.habanerogaming' },
        { title: 'FACHAI', key: 'game.fachai' },
        { title: 'SIMPLEPLAY', key: 'game.simpleplay' },
      ],
    }
  },
}
</script>

<style>

</style>
